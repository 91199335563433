import Alpine, { AlpineComponent } from 'alpinejs';
import { DateTime } from 'luxon';

import { getStoreHours } from './utils';

type SelectorTime = AlpineComponent<{
  now: DateTime;
  options: {
    hours: { disabled: boolean, label: string, value: number, selected: boolean }[];
    minutes: { disabled: boolean, label: string, value: number, selected: boolean }[];
    startingHour: number | undefined,
    startingMinute: number | undefined,
  };
  selected: {
    date: DateTime;
    hour: number | undefined;
    minute: number | undefined;
    ampm: string;
  };
  init: () => void;
  setHours: () => void;
  setMinutes: () => void;
  setOptions: () => void;
  validate: (date: DateTime) => boolean;
  handleSelectHour: () => void;
  handleSelectMinute: () => void;
}>;

const storeHours = getStoreHours();
const intervals: number[] = [0, 15, 30, 45];

Alpine.data('selectorTime', (): SelectorTime => ({
  now: DateTime.local().setZone('America/Chicago'),
  options: {
    hours: [],
    minutes: [],
    startingHour: undefined,
    startingMinute: undefined,
  },
  selected: {
    date: DateTime.local().setZone('America/Chicago'),
    hour: 7,
    minute: 0,
    ampm: 'am',
  },

  init() {

    document.addEventListener('scheduling-date-changed', (e) => {

      const detail = (e as CustomEvent).detail;

      if ( ! detail.date || ! /^\d{2}-\d{2}-\d{4}$/.test( detail.date )  ) return;

      this.options.startingHour = detail.startingHour;
      this.options.startingMinute = detail.startingMinute;

      this.selected.date = DateTime.fromFormat( detail.date, 'MM-dd-yyyy', { zone: 'America/Chicago' } );

      this.setOptions();

      this.$dispatch('scheduling-time-changed', { hour: this.selected.hour, minute: this.selected.minute, ampm: this.selected.ampm });
    });

  },

  setHours() {
    const option = this.selected.date.set({ hour: 0, minute: 0 });
    this.options.hours = [];
    this.selected.hour = undefined;

    for (let i = storeHours[this.selected.date.weekday - 1].opens + 1; i < storeHours[this.selected.date.weekday - 1].closes; i++) {

      const optionDate = option.set({ hour: i, minute: 0 });

      const hour = {
        disabled: !this.validate(optionDate),
        selected: false,
        label: optionDate.toFormat('h'),
        value: i
      };

      if (this.selected.date.hour === i && hour.disabled === false) {
        hour.selected = true;
        this.selected.hour = i;
        this.selected.ampm = optionDate.toFormat('a').toLowerCase();
      }

      // Check if starting hour is less than current hour
      if ( this.options.startingHour && this.options.startingHour > i ) {
        hour.disabled = true;
      }

      this.options.hours.push(hour);
    }

    if(!this.selected.hour) {
      const selected = this.options.hours[0];
      this.options.hours = [{ ...selected, selected: true }, ...this.options.hours.slice(1)];
      this.selected.hour = selected.value;
    }
  },

  setMinutes() {
    const option = this.selected.date.set({ hour: this.selected.hour, minute: 0 });
    const lastHour = this.selected.hour === storeHours[this.selected.date.weekday - 1].closes - 1;

    /* @ts-ignore */
    const delivery = this.$data.fulfillment.id === 'delivery';

    this.options.minutes = [];
    this.selected.minute = undefined;

    intervals.forEach((interval) => {
      const optionMinute = option.set({ minute: interval });

      const minute = {
        disabled: this.now > optionMinute || (lastHour && delivery && interval === 45),
        selected: false,
        label: optionMinute.toFormat('mm'),
        value: interval
      };

      if(this.selected.minute === undefined && minute.disabled === false) {
        minute.selected = true;
        this.selected.minute = interval;
      }
      // Check if starting minute is less than current minute
      if ( this.options.startingHour === this.selected.hour && this.options.startingMinute && this.options.startingMinute > interval ) {
        minute.disabled = true;
      }

      this.options.minutes.push(minute);
    });

    if(!this.selected.minute) {
      const selected = this.options.minutes[0];
      this.options.minutes = [{ ...selected, selected: true }, ...this.options.minutes.slice(1)];
      this.selected.minute = selected.value;
    }
  },

  setOptions() {
    this.setHours();
    this.setMinutes();
  },

  validate(date) {
    if(this.now > date) return false;
    if(this.now.toFormat('MM-dd-yyyy') === date.toFormat('MM-dd-yyyy') && this.now.hour > date.hour) return false;
    if(this.now.toFormat('MM-dd-yyyy') === date.toFormat('MM-dd-yyyy') && this.now.hour === date.hour && this.now.minute > 45) return false;
    return true;
  },

  handleSelectHour() {
    this.setMinutes();
    this.selected.ampm = this.selected.date.set({ hour: this.selected.hour }).toFormat('a').toLowerCase();
    this.$dispatch('scheduling-time-changed', { hour: this.selected.hour, minute: this.selected.minute, ampm: this.selected.ampm });
  },

  handleSelectMinute() {
    this.$dispatch('scheduling-time-changed', { hour: this.selected.hour, minute: this.selected.minute, ampm: this.selected.ampm });
  }
}));
