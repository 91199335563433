/* global google */

export type Place = {
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  formatted_address: string;
  geometry: {
    location: {
      lat: () => number;
      lng: () => number;
    };
  };
  name: string;
  place_id: string;
  types: string[];
};

export const parseAddress = (place: Place) => {
  const parsed = {
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    postcode: '',
    country: ''
  };

  for (const component of place.address_components) {
    // @ts-ignore remove once typings fixed
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        parsed.address_1 = `${component.long_name} ${parsed.address_1}`;
        break;
      }

      case "route": {
        parsed.address_1 += component.short_name;
        break;
      }

      case "postal_code": {
        parsed.postcode = `${component.long_name}${parsed.postcode}`;
        break;
      }

      case "postal_code_suffix": {
        parsed.postcode = `${parsed.postcode}-${component.long_name}`;
        break;
      }

      case "locality":
        parsed.city = component.long_name;
        break;

      case "administrative_area_level_1": {
        parsed.state = component.short_name;
        break;
      }

      case "country":
        parsed.country = 'US';
        break;
    }
  }

  return parsed;
};
