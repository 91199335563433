import Alpine, { AlpineComponent } from 'alpinejs';

import { productForm } from './utils';

type Product = AlpineComponent<{
    loading: boolean;
}>;

if ( productForm?.type === 'default' ) {
    productForm.form.setAttribute('x-data', 'product');
    Alpine.data( 'product', () => ( {
        loading: false,
    } as Product ) );
}
