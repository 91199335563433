const fields = [
  'billing_first_name',
  'billing_last_name',
  'billing_phone',
  'billing_email',
  'billing_address_1',
  'billing_address_2',
  'billing_city',
  'billing_postcode',
  'billing_state',
  'billing_country',
  'shipping_first_name',
  'shipping_last_name',
  'shipping_phone',
  'shipping_address_1',
  'shipping_address_2',
  'shipping_city',
  'shipping_postcode',
  'shipping_state',
  'shipping_country'
];

const rows = [
  'billing_country_field',
  'billing_company_field',
  'shipping_country_field',
  'shipping_company_field'
];

export const attachModels = () => {
  const billing = document.querySelector('#customer_details > .col-1');
  const shipping = document.querySelector('#customer_details > .col-2');
  const review = document.querySelector('#order_review');
  const coupon = document.querySelector('.e-coupon-box');
  const payment = document.querySelector('.e-checkout__order_review-2');

  billing?.setAttribute('x-show', 'step === "billing"');
  shipping?.setAttribute('x-show', 'step === "fulfillment"');
  review?.setAttribute('x-show', 'step === \'review\'');
  coupon?.setAttribute('x-show', 'step === \'review\'');
  payment?.setAttribute('x-show', 'step === \'review\'');

  attachMethods();

  fields.map((id: string) => {
    const input = document.getElementById(id);
    const attribute = id.replace('_', '.');

    if ( input ) {
      input.setAttribute('x-ref', attribute);
      input.setAttribute('x-model.fill', attribute);

      if(input.getAttribute('autocomplete') === 'tel') {
        input.setAttribute('x-mask', '(999) 999-9999');
      }
    }
  });

  rows.map((id: string) => {
    const row = document.getElementById(id);
    row?.setAttribute('x-show', 'false');
  });
}

export const attachMethods = () => {
  const methods = Array.from(document.querySelectorAll('#shipping_method input[name^="shipping_method"][type="radio"]'));

  if(methods.length) {
    methods.map((method: Element) => {
      method.setAttribute('x-model.fill', 'fulfillment.method');
    });
  }
};
