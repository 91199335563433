export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: number | undefined;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise(resolve => {
      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor)
    })
}

export const formatMoney = (amount: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return formatter.format(amount);
}

export const formatDateTime = () => {
  const date = new Date().toLocaleDateString('en-US', {
    timeZone: 'EST',
    month: "short",
    day: "numeric",
  });

  const time = new Date().toLocaleTimeString('en-US', {
    timeZone: 'EST',
    hour: "numeric",
    minute: "numeric"
  });

  return `${date} at ${time}`;
};

type Times = Record<'opens' | 'closes', number>;

export const getStoreHours = () => {
  const hours: Times[] = [
    { opens: 7, closes: 15 },
    { opens: 7, closes: 15 },
    { opens: 7, closes: 15 },
    { opens: 7, closes: 15 },
    { opens: 7, closes: 15 },
    { opens: 7, closes: 15 },
    { opens: 7, closes: 15 }
  ];

  return hours;
};

export const getFlavors = () => {
  return window.modularink_local_delivery?.flavors || [];
};

export const slugify = (...args: (string | number)[]): string => {
  const value = args.join(' ')

  return value
      .normalize('NFD') // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, '-') // separator
}

type FormType = {
  type: string,
  form: Element,
};

const productFormTypeMap = [
  {
    type: '48-pack',
    selector: 'body.product-template-default .product_cat-48-pack form.cart',
  },
  {
    type: 'variable',
    selector: 'body.product-template-default form.variations_form.cart',
  },
  {
    type: 'default',
    selector: 'body.product-template-default form.cart',
  },
];

const getFormType = () => {
  for ( const productForm of productFormTypeMap ) {
    const form = document.querySelector( productForm.selector );
    if ( form ) {
      return {
        form,
        type: productForm.type,
      };
    }
  }
  return undefined;
};

export const productForm: FormType | undefined = getFormType();
